body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

form {
    padding: 15px;
}

label {
    margin: 0 10px;
    font-weight: bold;
}

input {
    margin-left: 10px;
    border-radius: 3px;
    border: none;
    text-decoration: none;
    outline: none;
}


input {
    cursor: pointer;
}

.checkbox {
    margin: 0 20px;
}

.left-row {
    text-align: left;
    margin-left: 150px;
}

.row-bottom-margin {
    margin-bottom: 30px;
}
#test-fill-upload {
    float: left;
}

.error-box {
    background-color: red;
    border: 1px black solid;
    height: 20px;
    width: 600px;
    margin: auto;
    color: black;
}


.table-header {
    margin: 0 auto;
    width: 80%;
}

.table-header h3 {
    float: left;
}



.search-box {
    float: right;
}

button {
    border-radius: 12px;
    background-color: lightgreen;
    margin: 1px 0;
    height: 16px;
    font-size: 7pt;
}

.no-border {
    border: none;
}

.no-border td {
    border: none;
}



.table-wrapper {
    width: 500px;
    margin: 150px auto;
}

.csv-button {
    color: white;
}

.csv-button:hover {
    text-decoration: none;
}

h4 {
    text-align: left;
}

td {
    width: calc(500px/12);
}




#upload-page {
    padding-top: 55px;
}






:root {
    --table-size: 90vw;
    --td-small-size: calc(60vw/12);
    --td-large-size: calc(60vw/4);
}

.table-wrapper {
    margin: 0 auto;
    padding: 40px 0;
    width: 90vw;
    width: var(--table-size);
}


div.col {

}

div.container {
    width: 100%;
    padding: 0;
    max-width: 90vw;
    max-width: var(--table-size);
}

.cell {
    text-align: center;

}

input[type=submit] {
    border-radius: 12px;
    background-color: lightgreen;
    margin: 1px 0;
    height: 16px;
    font-size: 7pt;
    cursor: pointer;
    box-shadow: 0 1px 2px 2px gray;
}

input[type=submit]:active {
    box-shadow: none;
}

input {
    text-align: center
}
.update-record-form input[type=text] {
    background-color: #0091EA;
    color: white;
    width: 100%;
}

div.col.col-small,div.col.col-small input   {
    width: calc(90vw/12) !important;
    width: calc(var(--table-size)/12) !important;
}

div.col.col-large, div.col.col-large input {
    width: calc(90vw / 5) !important;
    width: calc(var(--table-size) / 5) !important;
}
input[name="API_KEY"] {
    -webkit-text-security: disc;
}
input[name="API_KEY"]:hover {
    -webkit-text-security: none;
}
html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Encode Sans', sans-serif;
}


a {
  color: #00B7FF;
}

div {
  display: block;
}

button {
  cursor: pointer;
}

.App {
  text-align: center;
}

.table td, .table th {
  padding: .25rem;
  vertical-align: middle;
}




.App-header {
  background-color: #0091EA;
  opacity: 0.7;
  min-height: calc(100vh - 53px);

  font-size: 12px;
  color: white;
}

.App-link {
  color: #61dafb;
}

.hidden {
  visibility: hidden;
  display: none;
}



.navbar-button {
    display: block;
    padding: 1px 1px 1px 1px;
    margin: 0px 2px;
    width: 90px;
    float: left;
    font-family: 'Encode Sans', sans-serif;
    height: 35px;
}

.navbar-button  p {
    text-align: center;
    margin-top: 10px;
    color: black;
}

.navbar-button a {
    text-decoration: none;
}

.navbar-nav > ul {
    float:left;
    margin-top: 0;
}

.navv {

    padding: 5px 0px 0px 0px;
    background-color: #fafafa;


}

.navbar-button:active {


}

.nav-logo-image {
    float: left;
    width: 100px;
}

.nav-logo-image > img {
    width: 150px;
}

.navbar-navv {
    float: right;
}

.navbar-inner {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 8px;
    height: 40px;

}
