.navbar-button {
    display: block;
    padding: 1px 1px 1px 1px;
    margin: 0px 2px;
    width: 90px;
    float: left;
    font-family: 'Encode Sans', sans-serif;
    height: 35px;
}

.navbar-button  p {
    text-align: center;
    margin-top: 10px;
    color: black;
}

.navbar-button a {
    text-decoration: none;
}

.navbar-nav > ul {
    float:left;
    margin-top: 0;
}

.navv {

    padding: 5px 0px 0px 0px;
    background-color: #fafafa;


}

.navbar-button:active {


}

.nav-logo-image {
    float: left;
    width: 100px;
}

.nav-logo-image > img {
    width: 150px;
}

.navbar-navv {
    float: right;
}

.navbar-inner {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 8px;
    height: 40px;

}