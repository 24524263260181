form {
    padding: 15px;
}

label {
    margin: 0 10px;
    font-weight: bold;
}

input {
    margin-left: 10px;
    border-radius: 3px;
    border: none;
    text-decoration: none;
    outline: none;
}