

.table-header {
    margin: 0 auto;
    width: 80%;
}

.table-header h3 {
    float: left;
}



.search-box {
    float: right;
}

button {
    border-radius: 12px;
    background-color: lightgreen;
    margin: 1px 0;
    height: 16px;
    font-size: 7pt;
}

.no-border {
    border: none;
}

.no-border td {
    border: none;
}