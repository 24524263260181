

input {
    cursor: pointer;
}

.checkbox {
    margin: 0 20px;
}

.left-row {
    text-align: left;
    margin-left: 150px;
}

.row-bottom-margin {
    margin-bottom: 30px;
}