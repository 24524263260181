#test-fill-upload {
    float: left;
}

.error-box {
    background-color: red;
    border: 1px black solid;
    height: 20px;
    width: 600px;
    margin: auto;
    color: black;
}