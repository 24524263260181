html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Encode Sans', sans-serif;
}


a {
  color: #00B7FF;
}

div {
  display: block;
}

button {
  cursor: pointer;
}

.App {
  text-align: center;
}

.table td, .table th {
  padding: .25rem;
  vertical-align: middle;
}




.App-header {
  background-color: #0091EA;
  opacity: 0.7;
  min-height: calc(100vh - 53px);

  font-size: 12px;
  color: white;
}

.App-link {
  color: #61dafb;
}

.hidden {
  visibility: hidden;
  display: none;
}


