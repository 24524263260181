
:root {
    --table-size: 90vw;
    --td-small-size: calc(60vw/12);
    --td-large-size: calc(60vw/4);
}

.table-wrapper {
    margin: 0 auto;
    padding: 40px 0;
    width: var(--table-size);
}


div.col {

}

div.container {
    width: 100%;
    padding: 0;
    max-width: var(--table-size);
}

.cell {
    text-align: center;

}

input[type=submit] {
    border-radius: 12px;
    background-color: lightgreen;
    margin: 1px 0;
    height: 16px;
    font-size: 7pt;
    cursor: pointer;
    box-shadow: 0 1px 2px 2px gray;
}

input[type=submit]:active {
    box-shadow: none;
}

input {
    text-align: center
}
.update-record-form input[type=text] {
    background-color: #0091EA;
    color: white;
    width: 100%;
}

div.col.col-small,div.col.col-small input   {
    width: calc(var(--table-size)/12) !important;
}

div.col.col-large, div.col.col-large input {
    width: calc(var(--table-size) / 5) !important;
}
input[name="API_KEY"] {
    -webkit-text-security: disc;
}
input[name="API_KEY"]:hover {
    -webkit-text-security: none;
}