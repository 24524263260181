


.table-wrapper {
    width: 500px;
    margin: 150px auto;
}

.csv-button {
    color: white;
}

.csv-button:hover {
    text-decoration: none;
}

h4 {
    text-align: left;
}

td {
    width: calc(500px/12);
}